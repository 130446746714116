@keyframes slideIn {
  0% {
    transform: translateX(-10%);
  }
  //   50% {
  //     transform: translateX(-5%);
  //   }
  100% {
    transform: translateX(0%);
  }
}
