.practice-area {
  display: flex;
  flex-wrap: wrap;

  & > div {
    width: 33.3%;
    padding: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .practice-area {
    & > div {
      width: 50%;
    }
  }
}

@media screen and (max-width: 500px) {
  .practice-area {
    & > div {
      width: 100%;
    }
  }
}
