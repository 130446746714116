*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 16px;

  body {
    box-sizing: border-box;
    font-family: "Nunito Sans", sans-serif;
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }
}
