.consult {
  .cancel {
    cursor: pointer;
  }
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: 10000;
  transition: 1s ease-in;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.8)
  );
  padding: 2rem 0;
  form {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 500px) {
  .consult {
    form {
      width: 90%;
    }
  }
}
