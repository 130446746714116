// import abstract folder
@import "./abstracts/variable";
@import "./abstracts/animation";

// import base folder
@import "./base/base";
@import "./base/typography";

// import components folder
@import "./components/button";
@import "./components/servicecard";
@import "./components/team";
@import "./components/modal";
@import "./components/practicecard";
@import "./components/loader";

//import Layout Folder
@import "./layout/skipcontent";
@import "./layout/navigation";
@import "./layout/footer";
// @import "./layout/popup";

// import from utils folder
@import "./utils/helpers";

// import pages folder
@import "./pages/home";
@import "./pages/contact";
@import "./pages/practicearea";

@import "../../node_modules/bootstrap/scss/bootstrap";
