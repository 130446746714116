nav {
  background-color: transparent;
}

.brand-img {
  height: 70px;
}

.navbar-nav {
  a {
    color: $primary-black;
    margin: 0 0.8rem;
    &:hover {
      color: $primary-black;
    }
  }

  button {
    margin-left: 0.8rem;
  }
}

.active-elem {
  color: $primary-blue;
  border-bottom: 1px solid $primary-blue;
}

@media screen and (max-width: 768px) {
  nav {
    .navbar-nav {
      align-items: flex-start !important;

      a {
        width: fit-content;
        margin-bottom: 0.3rem;
      }
    }
  }

  .btn-mobile {
    margin-top: 1rem;
  }
}
