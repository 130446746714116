//bg-color helper classes
.bg-white {
  background-color: $primary-white !important;
}

//color helper classes
.white {
  color: $primary-white !important;
}

.blue {
  color: $primary-blue;
}

//spacing helper classes
.lg-top {
  margin-top: 6rem;
}

.xl-top {
  margin-top: 8rem;
}

//fonts helper classes
.md-font {
  font-size: 2rem;
}

.sm-font {
  font-size: 1.5rem;
}

//font weight helper classes
.w-bold {
  font-weight: 700;
}

//filters on image classes
.img-grayscale {
  filter: grayscale(1);
  transition: 1s ease-in;

  &:hover {
    filter: grayscale(0.2);
  }
}

@media screen and (max-width: 500px) {
  .lg-top {
    margin-top: 6rem;
  }

  .xl-top {
    margin-top: 5rem;
  }

  .row {
    & > div:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}
