.btn-default {
  font-weight: 500;
  font-size: 1rem;
  padding: 0.4rem 1rem;
  border: none;
  color: $primary-white;
  border-radius: 10px;
  outline: none;
  background-color: $primary-blue;

  &:focus {
    outline: none;
  }
}

.btn-tran {
  background-color: transparent;
  border: none;

  &:focus {
    outline: none;
  }
}
