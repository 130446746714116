.loader-wrapper {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;

  img {
    animation-name: loader;
    animation-duration: 1.5s;
    height: 70px;
    animation-iteration-count: infinite;
    // animation-timing-function: linear;
  }
}

@keyframes loader {
  0% {
    transform: scale3d(1.5, 0.5, 1.5);
  }

  50% {
    transform: scale3d(1.5, 1.5, 1.5);
  }

  100% {
    transform: scale3d(2.5, 2, 1.5);
  }
}
