footer {
  background-color: $dark-blue;
  padding: 3rem 0;
  color: $primary-white;

  .footer-links {
    a {
      color: $primary-white;

      &:hover {
        color: $primary-white;
      }
      display: block;
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }
  }
}
