.team {
  margin-top: 2rem;
  position: relative;
  img {
    width: 100%;
    object-fit: cover;
    height: 300px;
  }
  &-details {
    background-image: linear-gradient(
      to right,
      rgba(40, 69, 130, 0.5),
      rgba(40, 69, 130, 0.5)
    );
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 1rem;
    line-height: 0.9;

    .name {
      font-size: 1.5rem;
    }

    .role {
      font-size: 1.3rem;
    }
  }
}
