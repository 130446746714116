.service-card {
  height: 150px;
  border-radius: 5px;
  padding: 1rem 0.5rem;

  img {
    display: block;
    margin: auto;
    margin-bottom: 0.4rem;
    height: 50px;
  }

  h3 {
    font-size: 1.5rem;
  }

  p {
    font-size: 0.9rem;
  }
}
