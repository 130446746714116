.home-header {
  background-image: linear-gradient(
      to right,
      rgba(40, 69, 130, 0.7),
      rgba(40, 69, 130, 0.7)
    ),
    url("../../asset/img/law.jpg");
  height: 500px;
  background-size: cover;
  width: 100%;
}

.home-about {
  background-image: linear-gradient(
      to right,
      rgba(40, 69, 130, 0.7),
      rgba(40, 69, 130, 0.7)
    ),
    url("../../asset/img/home-about.jpg");
  height: 450px;
  background-size: cover;
  width: 100%;
  background-position: bottom;
}

.heading-content {
  display: flex;
  height: inherit;
  backface-visibility: none;
  flex-direction: column;
  justify-content: center;
  animation: slideIn 0.7s ease-in;
  transition: all ease-in;

  .thin-line {
    height: 2px;
    width: 300px;
    background-color: $primary-white;
  }

  h1 {
    font-size: 3rem;
  }

  p {
    font-size: 1.125rem;
  }

  a {
    &:hover {
      color: $primary-white;
    }
  }
}

.sec-heading {
  font-size: 2rem;
  padding-left: 0.3rem;
  position: relative;

  &::before {
    content: "";
    height: 25px;
    top: 0.3rem;
    width: 2px;
    left: 0;
    position: absolute;
    background-color: $primary-blue;
  }
}

.polka {
  position: relative;
  &::before {
    content: "";
    background-image: radial-gradient($primary-white 1px, transparent 2px);
    background-size: 14px 14px;
    background-position: 0 0;
    position: absolute;
    height: 200px;
    // z-index: -1;
    width: 50%;
  }
}

.miniabt-img {
  margin-top: 3rem;
  margin-left: 2rem;
  filter: grayscale(1);
  transition: 0.5s ease-in;
  border-radius: 10px;

  &:hover {
    filter: grayscale(0);
  }
}

.miniabt-desc {
  padding: 0.5rem 2rem;
  display: flex;
  align-items: center;
  height: 100%;
}

.count-section {
  background-image: linear-gradient(
      to right,
      rgba(40, 69, 130, 0.7),
      rgba(40, 69, 130, 0.7)
    ),
    url("../../asset/img/office.jpg");
  height: 200px;
  background-size: cover;
  width: 100%;
  background-position: bottom;
  display: flex;
  align-items: center;
}

.count {
  p {
    margin-bottom: 0 !important;
  }
}

@media screen and (max-width: 500px) {
  .sec-heading {
    padding-top: 3rem;

    &::before {
      top: 3.3rem;
    }
  }
  .home-about {
    height: 700px;
  }

  .count-section {
    height: 330px;
  }
}
